import { map } from "lodash-es";
import React from "react";
import { STATES } from "src/util/data/geo";
import { SelectField } from "./SelectField";

const stateOptions = map(STATES, (label, value) => ({ label, value }));

export const StateSelectField: React.FC<
  React.ComponentProps<typeof SelectField>
> = (props) => <SelectField {...props} options={stateOptions} />;
