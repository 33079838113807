import {
  keepPreviousData,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { Company } from "src/types";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";
import {
  ApiResponse,
  ArchiveQueryConf,
  CollectionHook,
  CreateParams,
  DestroyParams,
  GetParams,
  PagedQueryConf,
  SearchQueryConf,
  SingleHook,
  SortableQueryConf,
  UpdateParams,
} from "./types";

//
// Types
//
type GetCompaniesParams = PagedQueryConf &
  SearchQueryConf &
  ArchiveQueryConf &
  SortableQueryConf;
type GetCompanyParams = GetParams;
type CreateCompanyParams = CreateParams<Company>;
type UpdateCompanyParams = UpdateParams<Company>;
type DestroyCompanyParams = DestroyParams;

//
// Networking
//

export type CompanyApiDetail = ApiResponse<{ company: Company }>;
export type CompaniesApiIndex = ApiResponse<{ companies: Company[] }>;

const getCompanies = async (
  params: GetCompaniesParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<CompaniesApiIndex>(ApiRoutes.companies(), {
    params,
    ...conf,
  });

  return resp.data;
};

const getCompany = async (
  { id }: GetCompanyParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<CompanyApiDetail>(ApiRoutes.company({ id }), conf);

  return resp.data;
};

const craeteCompany = async ({ data }: CreateCompanyParams) => {
  const resp = await api.post<CompanyApiDetail>(ApiRoutes.companies(), data);

  return resp.data;
};

const updateCompany = async ({ id, data }: UpdateCompanyParams) => {
  const resp = await api.patch<CompanyApiDetail>(
    ApiRoutes.company({ id }),
    data,
  );

  return resp.data;
};

const destroyCompany = async ({ id }: DestroyCompanyParams) => {
  await api.delete(ApiRoutes.company({ id }));
};

const archiveCompany = async ({ id }: DestroyCompanyParams) => {
  await api.post(ApiRoutes.archiveCompany({ id }));
};

const unarchiveCompany = async ({ id }: DestroyCompanyParams) => {
  await api.post(ApiRoutes.unarchiveCompany({ id }));
};

//
// Hooks
//
export const useCreateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: craeteCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });
};

export const useUpdateCompany = (
  id?: string,
): UseMutationResult<
  CompanyApiDetail,
  unknown,
  UpdateCompanyParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: UpdateCompanyParams) =>
      updateCompany(id ? { ...p, id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });
};

export const useDestroyCompany = (
  id?: string,
): UseMutationResult<void, unknown, DestroyCompanyParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyCompanyParams) => destroyCompany(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });
};

export const useArchiveCompany = (
  id?: string,
): UseMutationResult<void, unknown, DestroyCompanyParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyCompanyParams) => archiveCompany(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });
};

export const useUnarchiveCompany = (
  id?: string,
): UseMutationResult<void, unknown, DestroyCompanyParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyCompanyParams) => unarchiveCompany(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
    },
  });
};

export const useCompany = (
  params: GetCompanyParams,
): SingleHook<Company, CompanyApiDetail> => {
  const query = useQuery({
    queryKey: ["companies", params],
    queryFn: ({ signal }) => getCompany(params, { signal }),
  });

  const update = useUpdateCompany(params.id);
  const destroy = useDestroyCompany(params.id);

  return { data: query.data, query, update, destroy };
};

export const useCompanies = (
  params: GetCompaniesParams = {},
): CollectionHook<Company, CompaniesApiIndex, CompanyApiDetail> => {
  const query = useQuery({
    queryKey: ["companies", params],
    placeholderData: keepPreviousData,
    queryFn: ({ signal }) => getCompanies(params, { signal }),
  });

  const create = useCreateCompany();
  const update = useUpdateCompany();
  const destroy = useDestroyCompany();

  return { data: query.data, query, create, update, destroy };
};
