import { Button, ButtonProps } from "@ream/ui";
import { useFormState } from "react-final-form";

export const FormSubmitButton: React.FC<ButtonProps> = ({
  children,
  loading,
  disabled = false,
  disabledTooltip,
  ...rest
}) => {
  const { submitting } = useFormState();

  const isDisabled = disabled || submitting;

  const isLoading = loading || submitting;

  const tooltip =
    disabled && Boolean(disabledTooltip) ? disabledTooltip : undefined;

  return (
    <Button
      type="submit"
      loading={isLoading}
      disabled={isDisabled}
      disabledTooltip={tooltip}
      {...rest}
    >
      {children}
    </Button>
  );
};
