import { Icon, LoadingGate } from "@ream/ui";
import clsx from "clsx";
import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { FormControl, ListGroup, Stack } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { Option } from "src/types";

type Props<T extends unknown = unknown> = {
  options: Option<T>[];
  onSelectItem: (value: T, item: Option<T>, idx: number) => void;
  hideFilter?: boolean;
  filterValue?: string;
  onCreate?: (v: string) => void;
  onSearch?: (v: string) => void;
  flush?: boolean;
  loading?: boolean;
  loadingNode?: React.ReactNode;
  disabledWhileLoading?: boolean;
  placeholder?: string;
  helpText?: React.ReactNode;
  optionComponent?: React.ElementType<FilterMenuOptionRenderProps<T>>;
  menuRef?: Ref<FilterMenuRef>;
} & React.HTMLProps<HTMLDivElement>;

export type FilterMenuRef = {
  onKeyDown: (_e: React.KeyboardEvent<Element>) => boolean;
};

export type FilterMenuOptionRenderProps<T extends unknown = unknown> = {
  option: Option<T>;
};

const OptionRender: React.FC<FilterMenuOptionRenderProps> = ({
  option: { label, icon, decoration: Decoration, description, help, helpTitle },
}) => {
  return (
    <span className="d-flex gap-2 align-items-center">
      {icon && <Icon size={14} icon={icon} />}

      <div className="flex-grow">
        <div className="d-flex flex-row align-items-center gap-2">
          {Decoration && <Decoration />}

          <Stack direction="vertical" gap={0}>
            <span className="text-nowrap text-truncate">{label}</span>

            {Boolean(help) && (
              <span
                className="text-muted small text-nowrap text-truncate"
                title={helpTitle}
              >
                {help}
              </span>
            )}
          </Stack>
        </div>
      </div>

      {Boolean(description) && (
        <span className="text-muted small">{description}</span>
      )}
    </span>
  );
};

type OptionItemProps<T extends unknown = unknown> = {
  option: Option<T>;
  selected: boolean;
  onClick: (o: Option<T>) => T | Promise<T>;
  children: React.ReactNode;
};
const OptionItem: React.FC<OptionItemProps> = ({
  option,
  selected,
  onClick,
  children,
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (selected && !inView && entry) {
      entry.target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selected, inView, entry]);

  return (
    <ListGroup.Item
      ref={ref}
      onClick={() => onClick(option)}
      className={clsx(selected && "surface-3", "py-1 px-2")}
      action
      disabled={option.disabled ?? false}
      type="button"
    >
      {children}
    </ListGroup.Item>
  );
};

export const FilterMenu = <T extends unknown = unknown>({
  options,
  onSelectItem,
  style,
  className,
  hideFilter = false,
  filterValue = "",
  "aria-labelledby": labeledBy,
  onCreate,
  onSearch,
  loading = false,
  loadingNode,
  disabledWhileLoading = true,
  flush = false,
  placeholder,
  helpText,
  optionComponent,
  menuRef: menuRef,
}: Props<T>) => {
  const OptionComponent = optionComponent ?? OptionRender;
  const [value, setValue] = useState(filterValue);
  const [selectedIdx, setSelectedIdx] = useState<null | number>(null);

  const filteredOptions = options.filter((o) => {
    const normalizedValue = value?.toLowerCase()?.trim();
    return (
      o.value?.toString()?.toLowerCase()?.includes(normalizedValue) ||
      o.label?.toLowerCase()?.includes(normalizedValue)
    );
  });

  const decrement = () => {
    setSelectedIdx(
      ((selectedIdx ?? -1) + filteredOptions.length - 1) %
        filteredOptions.length,
    );
  };

  const increment = () => {
    setSelectedIdx(((selectedIdx ?? -1) + 1) % filteredOptions.length);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    onSearch?.(e.target.value);
    setSelectedIdx(null);
  };

  const allowCreate = Boolean(onCreate);
  const createEnabled = Boolean(value) && filteredOptions.length < 1;

  const doSelect = (value: any, item: Option<any>, idx: number) => {
    onSelectItem(value, item, idx);
    setValue("");
  };

  const handleSelect = (idx: number | null = null) => {
    const selectionIdx = idx ?? selectedIdx;

    if (selectionIdx != null) {
      const selected = filteredOptions[selectionIdx];

      if (selected) {
        doSelect(selected.value, selected, selectionIdx);
      }
    }
  };

  const handleCreate = (value: string) => {
    if (onCreate) {
      onCreate(value);
      setValue("");
    }
  };

  const handleArrowKeyNav = (e: React.KeyboardEvent<Element>): boolean => {
    if (e.key === "ArrowUp") {
      decrement();
      return true;
    }

    if (e.key === "ArrowDown") {
      increment();
      return true;
    }

    if (e.key === "Enter") {
      e.preventDefault();

      if (filteredOptions.length === 1) {
        const item = filteredOptions[0];
        doSelect(item.value, item, 0);
      }

      if (filteredOptions.length === 0 && allowCreate && createEnabled) {
        handleCreate(value);
      } else {
        handleSelect();
      }

      return true;
    }

    return false;
  };

  useImperativeHandle(menuRef, () => ({
    onKeyDown: handleArrowKeyNav,
  }));

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  return (
    <div
      style={style}
      className={clsx(className, "filter_menu", flush && "filter_menu--flush")}
      aria-labelledby={labeledBy}
      onMouseOver={() => setSelectedIdx(null)}
    >
      {!hideFilter && (
        <div className="filter_menu__search">
          <FormControl
            value={value}
            onChange={handleChange}
            autoFocus
            size="sm"
            type="search"
            placeholder={
              placeholder ??
              (allowCreate ? "Filter or Create" : "Filter Options")
            }
            className="w-100"
            disabled={disabledWhileLoading && loading}
            onKeyDown={handleArrowKeyNav}
          />
        </div>
      )}
      <LoadingGate loading={loading} loadingNode={loadingNode}>
        <ListGroup className="filter_menu__list" variant="flush">
          {filteredOptions.length > 0 ? (
            <>
              {filteredOptions.map((o, idx) => {
                return (
                  <OptionItem
                    key={`${o.label}-${idx}`}
                    selected={
                      idx === selectedIdx || filteredOptions.length === 1
                    }
                    onClick={() => handleSelect(idx)}
                    option={o}
                  >
                    <OptionComponent option={o} />
                  </OptionItem>
                );
              })}
            </>
          ) : (
            <ListGroup.Item
              disabled={!createEnabled}
              action
              onClick={allowCreate ? () => handleCreate(value) : undefined}
            >
              {allowCreate && createEnabled ? (
                <span className="text-info">Create &ldquo;{value}&rdquo;</span>
              ) : (
                <>No matches.</>
              )}
            </ListGroup.Item>
          )}
        </ListGroup>
      </LoadingGate>

      {Boolean(helpText) && (
        <div className="filter_menu__footer small text-muted">{helpText}</div>
      )}
    </div>
  );
};

export const DropdownFilterMenu = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof FilterMenu>
>(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(className, "filter_menu_dropdown")}
    style={style}
  >
    <FilterMenu flush {...props} />
  </div>
));

DropdownFilterMenu.displayName = "FilterMenu";
