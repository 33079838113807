import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { CreateSelect, Select } from "src/components/Select";

export type SelectFieldProps = {
  input: any;
  meta: any;
  label?: string;
  readOnly?: boolean;
  includeBlank?: boolean;
  disabled?: boolean;
  includeMargin?: boolean;
  onCreateOption?: (_inputValue: string) => void;
  loading?: boolean;
  required?: boolean;
  helpText?: React.ReactNode;
  multiple?: boolean;
} & React.ComponentProps<typeof Form.Group>;

import { useSelectInputHandlers } from "src/util/forms";
import { FieldWrapper } from "./FieldWrapper";

export const SelectField: React.FC<SelectFieldProps> = React.forwardRef(
  (props, ref) => {
    const {
      isMulti,
      multiple,
      input,
      loading,
      includeBlank,
      onCreateOption,
      disabled,
      readOnly,
      options,
      meta,
    } = props;

    const SelectComponent = onCreateOption ? CreateSelect : Select;

    const { filterFunc, selectFunc, handleChange } = useSelectInputHandlers(
      isMulti || multiple || input.multiple,
      input.value,
      input.onChange,
    );

    const selectOptions = useMemo(
      () =>
        includeBlank
          ? [{ label: "Please Select...", value: "" }, ...options]
          : options,
      [includeBlank, options],
    );

    return (
      <FieldWrapper {...props}>
        {({ input }) => (
          <SelectComponent
            {...input}
            meta={meta}
            ref={ref}
            options={selectOptions}
            value={filterFunc(selectOptions, selectFunc)}
            onChange={handleChange}
            onCreateOption={onCreateOption}
            isMulti={isMulti || multiple || input.multiple}
            isDisabled={disabled || readOnly || loading}
            isLoading={loading}
            searchable
          />
        )}
      </FieldWrapper>
    );
  },
);

SelectField.displayName = "SelectField";
