import { BaseOverlay, Empty } from "@ream/ui";
import { CheckIcon } from "lucide-react";
import React, { useMemo } from "react";
import { OverlayTriggerProps } from "react-bootstrap";
import { Agentable, AgentableId, Option } from "src/types";
import { ContactApiDetail, useCreateContact } from "src/util/api/contactsApi";
import { useOrgPeopleSearch } from "src/util/api/orgsApi";
import { splitFullName } from "src/util/models/contacts";
import { elideEmail } from "src/util/utils";
import { UserAvatar } from "../Avatar";
import { FilterMenu } from "../FilterMenu";

export const ManagePeoplePopover: React.FC<{
  selectedIds: string[];
  trigger: React.ReactElement;
  overlayProps?: Partial<Omit<OverlayTriggerProps, "children">>;
  multiple?: boolean;
  onAdd: (_personId: AgentableId, _person: Agentable) => any | Promise<any>;
  onRemove: (_personId: AgentableId, _person: Agentable) => any | Promise<any>;
  allowCreate?: boolean;
  loading?: boolean;
  disabled?: boolean;
  disableWhileLoading?: boolean;
  width?: number;
  onlyInternal?: boolean;
  onlyExternal?: boolean;
}> = ({
  selectedIds = [],
  onAdd,
  onRemove,
  multiple = false,
  trigger,
  overlayProps,
  allowCreate = false,
  loading = false,
  disabled = false,
  disableWhileLoading = true,
  width,
  onlyInternal = false,
  onlyExternal = false,
}) => {
  const createContact = useCreateContact();

  const { data: { people } = { people: [] }, query } = useOrgPeopleSearch({
    per: 1000,
  });

  const options = useMemo<Option<AgentableId>[]>(() => {
    return people
      .filter((p) => {
        if (onlyInternal && p.personType !== "internal") {
          return false;
        }
        if (onlyExternal && p.personType !== "external") {
          return false;
        }
        return true;
      })
      .map((p) => {
        const selected = selectedIds.includes(p.publicUid);

        return {
          icon: selected ? CheckIcon : Empty,
          decoration: () => <UserAvatar user={p} size={14} />,
          value: p.publicUid,
          label: p.fullName,
          description: p.companyName,
          help: elideEmail(p.email, 50),
          helpTitle: p.email,
        };
      });
  }, [selectedIds, onlyInternal, onlyExternal, people]);

  const triggerComponent = React.cloneElement(trigger, {
    loading: loading || query.isFetching,
    disabled: disabled || (disableWhileLoading && query.isFetching),
  });

  const onCreate = async (name: string) => {
    const { firstName, lastName } = splitFullName(name);

    return createContact.mutateAsync(
      {
        data: {
          firstName,
          lastName,
        },
      },
      {
        onSuccess: async (res: ContactApiDetail) => {
          return await onAdd(res.contact.publicUid, {
            ...res.contact,
            personType: "external",
          });
        },
      },
    );
  };

  return (
    <BaseOverlay
      placement="bottom"
      {...overlayProps}
      trigger={triggerComponent}
    >
      {({ handleClose }) => {
        const handleAdd = async (personId: AgentableId, person: Agentable) =>
          await onAdd(personId, person);
        const handleRemove = async (personId: AgentableId, person: Agentable) =>
          await onRemove(personId, person);

        const onSelect = async (personId: AgentableId) => {
          const person = people.find((p) => p.publicUid === personId);
          if (!person) {
            return;
          }

          const wasSelected = selectedIds.includes(personId);

          if (!multiple) {
            handleClose();
          }

          return wasSelected
            ? await handleRemove(personId, person)
            : await handleAdd(personId, person);
        };

        const handleCreate = async (value: string) => {
          if (onCreate) {
            if (!multiple) {
              handleClose();
            }

            return await onCreate(value);
          }
        };

        return (
          <FilterMenu
            className="shadow"
            loading={loading}
            onCreate={allowCreate ? handleCreate : undefined}
            onSelectItem={onSelect}
            options={options}
            style={{ width }}
          />
        );
      }}
    </BaseOverlay>
  );
};
