import { merge, set } from "lodash-es";
import { ObjectSchema } from "yup";

// TODO: Type This Strongly?
export const validateSchema =
  (schema: ObjectSchema<any> | (() => ObjectSchema<any>)) =>
  async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }

    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError, innerError) => {
        return set(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };

type ValidatorFn = (values: any) => Promise<Object>;

export const composeValidations = (
  ...validators: ValidatorFn[]
): ValidatorFn => {
  return async (values: any) => {
    const errors = {};
    for (const v of validators) {
      const errs = await v(values);
      merge(errors, errs);
    }
    return errors;
  };
};
