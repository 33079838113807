import {
  keepPreviousData,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { Contact } from "src/types";
import { api, fileFromResponse } from "../api";
import { ApiRoutes } from "../apiRoutes";
import {
  ApiResponse,
  ArchiveQueryConf,
  CollectionHook,
  CreateParams,
  DestroyParams,
  GetParams,
  PagedQueryConf,
  SearchQueryConf,
  SingleHook,
  SortableQueryConf,
  UpdateParams,
} from "./types";

//
// Types
//
export type GetContactsParams = PagedQueryConf &
  SearchQueryConf &
  SortableQueryConf &
  ArchiveQueryConf;
type GetContactParams = GetParams;
type CreateContactParams = CreateParams<Contact>;
type UpdateContactParams = UpdateParams<Contact>;
type MoveContactParams = UpdateParams<
  Contact & { companyId: string; archive?: boolean }
>;
type DestroyContactParams = DestroyParams;

//
// Networking
//

export type ContactApiDetail = ApiResponse<{ contact: Contact }>;
export type ContactsApiIndex = ApiResponse<{ contacts: Contact[] }>;

const getContacts = async (
  params: GetContactsParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<ContactsApiIndex>(ApiRoutes.contacts(), {
    params,
    ...conf,
  });

  return resp.data;
};

const getContactsForCompany = async (
  { companyId, ...params }: GetContactsParams & { companyId: string },
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<ContactsApiIndex>(
    ApiRoutes.companyContacts({ companyId }),
    {
      params,
      ...conf,
    },
  );

  return resp.data;
};

const getContact = async (
  { id }: GetContactParams,
  conf: AxiosRequestConfig,
) => {
  const resp = await api.get<ContactApiDetail>(ApiRoutes.contact({ id }), conf);

  return resp.data;
};

const createContact = async ({ data }: CreateContactParams) => {
  const resp = await api.post<ContactApiDetail>(ApiRoutes.contacts(), data);

  return resp.data;
};

const updateContact = async ({ id, data }: UpdateContactParams) => {
  const resp = await api.patch<ContactApiDetail>(
    ApiRoutes.contact({ id }),
    data,
  );

  return resp.data;
};

const moveContact = async ({ id, data }: MoveContactParams) => {
  const resp = await api.post<ContactApiDetail>(
    ApiRoutes.moveContact({ id }),
    data,
  );

  return resp.data;
};

const destroyContact = async ({ id }: DestroyContactParams) => {
  await api.delete(ApiRoutes.contact({ id }));
};

const archiveContact = async ({ id }: DestroyContactParams) => {
  await api.post(ApiRoutes.archiveContact({ id }));
};

const unarchiveContact = async ({ id }: DestroyContactParams) => {
  await api.post(ApiRoutes.unarchiveContact({ id }));
};

const importContacts = async (csv: string) => {
  const resp = await api.post<ContactsApiIndex>(ApiRoutes.importContacts(), {
    csv,
  });

  return resp.data;
};

const getSampleCsv = async (conf: AxiosRequestConfig) => {
  const resp = await api.get<Blob>(ApiRoutes.sampleImportCsv(), {
    responseType: "blob",
    timeout: 10000,
    ...conf,
  });

  return fileFromResponse(resp, "import_contacts.csv", ".csv");
};

//
// Hooks
//
export const useCreateContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createContact,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useImportContacts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: importContacts,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useDownloadSampleCsv = () => {
  const query = useQuery({
    queryFn: ({ signal }) => getSampleCsv({ signal }),
    queryKey: ["contacts", "sample_csv"],
    enabled: false,
  });

  useEffect(() => {
    if (query.data) {
      saveAs(query.data);
    }
  }, [query.data]);

  return query;
};

export const useUpdateContact = (
  id?: string,
): UseMutationResult<
  ContactApiDetail,
  unknown,
  UpdateContactParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: UpdateContactParams) =>
      updateContact(id ? { ...p, id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useArchiveContact = (
  id?: string,
): UseMutationResult<void, unknown, DestroyContactParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyContactParams) => archiveContact(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });

      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useUnarchiveContact = (
  id?: string,
): UseMutationResult<void, unknown, DestroyContactParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyContactParams) => unarchiveContact(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });

      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useDestroyContact = (
  id?: string,
): UseMutationResult<void, unknown, DestroyContactParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: DestroyContactParams) => destroyContact(id ? { id } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useMoveContact = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: MoveContactParams) => moveContact(id ? { id, ...p } : p),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["org", "people"],
      });
    },
  });
};

export const useContact = (
  params: GetContactParams,
): SingleHook<Contact, ContactApiDetail> => {
  const query = useQuery({
    queryKey: ["contacts", params],
    queryFn: ({ signal }) => getContact(params, { signal }),
  });

  const update = useUpdateContact(params.id);
  const destroy = useDestroyContact(params.id);

  return { data: query.data, query, update, destroy };
};

export const useContacts = (
  params: GetContactsParams = {},
): CollectionHook<Contact, ContactsApiIndex, ContactApiDetail> => {
  const query = useQuery({
    queryKey: ["contacts", params],
    placeholderData: keepPreviousData,
    queryFn: ({ signal }) => getContacts(params, { signal }),
  });

  const create = useCreateContact();
  const update = useUpdateContact();
  const destroy = useDestroyContact();

  return { data: query.data, query, create, update, destroy };
};

export const useContactsForCompany = (
  companyId: string,
  params: GetContactsParams = {},
) => {
  const query = useQuery({
    queryKey: ["contacts", companyId, params],
    placeholderData: keepPreviousData,
    queryFn: ({ signal }) =>
      getContactsForCompany({ companyId, ...params }, { signal }),
  });

  return { data: query.data, query };
};
