import { Form } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { FieldWrapper } from "./FieldWrapper";

type Props = FieldRenderProps<string, any> & { required?: boolean };

export const TextField: React.FC<Props> = (props) => {
  return (
    <FieldWrapper {...props}>
      {({ input, showError, hasError, ...rest }) => (
        <Form.Control {...input} {...rest} isInvalid={hasError && showError} />
      )}
    </FieldWrapper>
  );
};
