import { Contact, ContactSummary } from "src/types";

export const contactPosition = (contact: Contact | ContactSummary) => {
  let position = "--";

  if (contact.companyName && contact.title) {
    position = `${contact.title} @ ${contact.companyName}`;
  } else if (contact.companyName && !contact.title) {
    position = `@ ${contact.companyName}`;
  } else if (contact.title && !contact.companyName) {
    position = contact.title;
  }

  return contact.archived ? `${position} (archived)` : position;
};

export const splitFullName = (fullName: string) => {
  const [firstName, ...rest] = fullName.split(" ");
  const lastName = rest.join(" ");

  return { firstName, lastName };
};
