import { filter, find, includes } from "lodash-es";
import { FieldMetaState } from "react-final-form";
import { Option } from "src/types";

export const errorState = (
  meta: FieldMetaState<unknown>,
  hideUntilSubmitted: boolean = false,
) =>
  simpleErrorState(
    meta.error,
    meta.submitError,
    meta.dirtySinceLastSubmit,
    meta.dirty || meta.touched,
    meta.submitFailed,
    hideUntilSubmitted,
  );

export const simpleErrorState = (
  error: string | undefined,
  submitError: string | undefined,
  dirtySinceLastSubmit: boolean | undefined,
  dirty: boolean | undefined,
  submitFailed: boolean | undefined,
  hideUntilSubmitted: boolean = false,
) => {
  submitError = dirtySinceLastSubmit ? undefined : submitError;

  const shouldShow = hideUntilSubmitted
    ? dirty && submitFailed
    : dirty || submitFailed;

  const hasError = Boolean(error) || Boolean(submitError);
  const showError = Boolean(shouldShow && hasError);

  return { error: error ?? submitError, hasError, showError };
};

export const useSelectInputHandlers = <T extends unknown = any>(
  isMulti: boolean,
  value: T | T[],
  onChange: (_newValue: T | T[] | null) => void,
) => {
  const filterFunc = isMulti ? filter : find;
  const selectFunc = isMulti
    ? (o: Option<T>) => includes(value as T[], o.value)
    : (o: Option<T>) => o.value === value;

  const handleChange = isMulti
    ? (selected: Option<T>[]) =>
        selected
          ? onChange([selected].flat().map((v) => v.value))
          : onChange(null)
    : (selected: Option<T>) =>
        selected ? onChange(selected.value) : onChange(null);

  return { filterFunc, selectFunc, handleChange };
};
