import clsx from "clsx";
import React from "react";
import { Form } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { errorState } from "src/util/forms";
import { RequiredMark } from "./RequiredMark";

export const FieldLabel: React.FC<{
  label?: React.ReactNode;
  required?: boolean;
}> = ({ label, required }) => {
  if (!label) {
    return null;
  }
  return (
    <Form.Label aria-required={required}>
      {label}
      {required && <RequiredMark />}
    </Form.Label>
  );
};

type FieldGroupProps = {
  className?: string;
  children: React.ReactNode;
  as?: React.ElementType;
};

export const FieldGroup = React.forwardRef<HTMLDivElement, FieldGroupProps>(
  ({ className = "", as, children, ...rest }, ref) => {
    return (
      <Form.Group ref={ref} className={clsx(className)} as={as} {...rest}>
        {children}
      </Form.Group>
    );
  },
);

FieldGroup.displayName = "FieldGroup";

export const FieldError: React.FC<{
  error?: React.ReactNode;
  show: boolean;
}> = ({ error, show = false }) =>
  show ? <Form.Text className="text-danger">{error}</Form.Text> : null;

export const FieldHelp: React.FC<{
  helpText?: React.ReactNode;
  className?: string;
}> = ({ helpText, className }) =>
  helpText ? <Form.Text className={className}>{helpText}</Form.Text> : null;

export type FieldWrapperProps = FieldRenderProps<unknown, any> & {
  required?: boolean;
  suppressErrorUntilSubmit?: boolean;
};

export const FieldWrapper = React.forwardRef<HTMLDivElement, FieldWrapperProps>(
  (
    {
      input,
      meta,
      label,
      className,
      helpText,
      required = false,
      children,
      suppressErrorUntilSubmitted = false,
      ...rest
    },
    ref,
  ) => {
    const { error, hasError, showError } = errorState(
      meta,
      suppressErrorUntilSubmitted,
    );

    return (
      <FieldGroup className={className} ref={ref}>
        <FieldLabel label={label} required={required} />

        {children({ input, meta, showError, hasError, required, ...rest })}

        {showError ? (
          <FieldError error={error} show={showError} />
        ) : (
          <FieldHelp helpText={helpText} />
        )}
      </FieldGroup>
    );
  },
);

FieldWrapper.displayName = "FieldWrapper";
