import { Alert, BaseModal, Button } from "@ream/ui";
import React from "react";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import { Field, Form, useFormState } from "react-final-form";
import { TextField } from "src/components/form/TextField";
import { AgentableId, Company, Contact } from "src/types";
import { extractErrorMessage } from "src/util/api/apiError";
import { useCreateContact } from "src/util/api/contactsApi";
import { validateSchema } from "src/util/validateSchema";
import * as yup from "yup";
import { AddressFields } from "../form/AddressFields";
import { CompanySelectField } from "../form/CompanySelectField";
import { FormSubmitButton } from "../form/FormSubmitButton";

export const contactSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  title: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  faxNumber: yup.string().nullable(),
  website: yup.string().url().nullable(),
  companyId: yup.string(),
  address: yup
    .object()
    .nullable()
    .shape({
      street: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup.string().nullable(),
      zip: yup.string().length(5).nullable(),
      country: yup.string().nullable(),
    }),
});

export type ContactFormData = Pick<
  Contact,
  | "firstName"
  | "lastName"
  | "email"
  | "title"
  | "phoneNumber"
  | "faxNumber"
  | "website"
  | "address"
> & {
  company?: Company;
  publicUid?: AgentableId;
  companyId?: string;
};

export const ContactFields: React.FC<{ hideCompanyField?: boolean }> = ({
  hideCompanyField = false,
}) => {
  const { values } = useFormState<ContactFormData>();

  return (
    <Stack gap={3}>
      <Field
        label="Company"
        name="companyId"
        component={hideCompanyField ? "input" : CompanySelectField}
        type={hideCompanyField ? "hidden" : undefined}
        disabled={Boolean(values.company)}
        helpText={
          values.company
            ? "Company cannot be edited. Create a new contact at the company instead."
            : undefined
        }
        allowCreate
      />

      {(Boolean(values.companyId) ||
        Boolean(values.company) ||
        Boolean(values.publicUid)) && (
        <>
          <Row>
            <Col>
              <Field
                label="First Name"
                required
                name="firstName"
                component={TextField}
              />
            </Col>
            <Col>
              <Field
                label="Last Name"
                required
                name="lastName"
                component={TextField}
              />
            </Col>
          </Row>

          <Field label="Title" name="title" component={TextField} />

          <Field
            label="Email"
            required
            name="email"
            component={TextField}
            type="email"
          />

          <Row>
            <Col>
              <Field
                label="Phone Number"
                name="phoneNumber"
                component={TextField}
                type="tel"
              />
            </Col>
            <Col>
              <Field
                label="Fax Number"
                name="faxNumber"
                component={TextField}
                type="tel"
              />
            </Col>
          </Row>

          <Field
            label="Website"
            name="website"
            component={TextField}
            type="url"
          />

          <AddressFields />
        </>
      )}
    </Stack>
  );
};

type Props = {
  companyId?: string;
  onContactAdded?: () => void;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
};

export const AddContactModal: React.FC<Props> = ({
  companyId,
  onContactAdded,
  buttonProps,
}) => {
  const create = useCreateContact();

  return (
    <BaseModal
      size="lg"
      trigger={<Button {...buttonProps}>Add Contact</Button>}
      title="Add new Contact..."
      dismissable
    >
      {({ handleClose }) => {
        const onSubmit = async (formData: ContactFormData) => {
          return create.mutateAsync(
            { data: formData },
            {
              onSuccess: () => {
                if (onContactAdded) {
                  onContactAdded();
                }

                handleClose();
              },
            },
          );
        };

        return (
          <>
            <Form
              onSubmit={onSubmit}
              validate={validateSchema(contactSchema)}
              keepDirtyOnReinitialize
              initialValues={{ companyId }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    {create.isError && (
                      <Alert variant="danger">
                        {extractErrorMessage(create.error as Error)}
                      </Alert>
                    )}
                    <ContactFields hideCompanyField={Boolean(companyId)} />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={handleClose}
                      disabled={submitting}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <FormSubmitButton
                      disabledTooltip={"You haven't made any changes yet!"}
                    >
                      Add Contact
                    </FormSubmitButton>
                  </Modal.Footer>
                </form>
              )}
            />
          </>
        );
      }}
    </BaseModal>
  );
};
