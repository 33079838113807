import { Button } from "@ream/ui";
import clsx from "clsx";
import { truncate } from "lodash-es";
import { Edit2Icon, XIcon } from "lucide-react";
import { ListGroupItem, ListGroupItemProps, Stack } from "react-bootstrap";
import { UserAvatar } from "src/components/Avatar";
import { Agentable, AgentableId } from "src/types";
import { elideEmail } from "src/util/utils";
import { EditContactByIdModal } from "./modals/EditContactModal";

type Props = {
  person: Agentable;
  onRemove?: (personId: AgentableId) => void | Promise<void>;
  isRemoving?: boolean;
  allowEdit?: boolean;
  hideCompany?: boolean;
  hideEmail?: boolean;
} & ListGroupItemProps;

const description = (
  person: Agentable,
  showCompany: boolean,
  showEmail: boolean,
) => {
  if (showCompany && person.companyName) {
    return truncate(person.companyName);
  }

  if (showEmail && person.email) {
    return elideEmail(person.email, 25);
  }

  return undefined;
};

const descriptionTooltip = (
  person: Agentable,
  showCompany: boolean,
  showEmail: boolean,
) => {
  if (showCompany && person.companyName) {
    return person.companyName;
  }

  if (showEmail && person.email) {
    return person.email;
  }

  return undefined;
};

export const PeopleListItem: React.FC<Props> = ({
  person,
  className,
  allowEdit,
  isRemoving = false,
  onRemove,
  children,
  hideCompany = false,
  hideEmail = false,
  ...rest
}) => {
  return (
    <ListGroupItem
      id={person.publicUid}
      className={clsx("d-flex align-items-center", className)}
      {...rest}
    >
      <Stack
        className="align-items-center flex-grow  overflow-hidden"
        direction="horizontal"
        gap={2}
      >
        <UserAvatar user={person} className="flex-shrink-0" size={16} />

        <Stack gap={0} className="minw-0 overflow-hidden">
          <span className="text-nowrap text-truncate">{person.fullName}</span>
          <span
            className="small text-muted text-nowrap text-truncate"
            title={descriptionTooltip(person, !hideCompany, !hideEmail)}
          >
            {description(person, !hideCompany, !hideEmail)}
          </span>
        </Stack>
      </Stack>

      <div className="ms-auto d-flex flex-shrink-0 align-items-center gap-2">
        {children}

        <span className="ms-auto d-flex align-items-center">
          {person.personType === "external" && allowEdit && (
            <EditContactByIdModal
              contactId={person.publicUid}
              buttonProps={{
                size: "sm",
                variant: "icon",
                StartIcon: Edit2Icon,
                iconOnly: true,
              }}
            />
          )}

          {onRemove && !rest.disabled && (
            <Button
              loading={isRemoving}
              onClick={() => onRemove(person.publicUid)}
              size="sm"
              variant="icon"
              StartIcon={XIcon}
              iconOnly
            />
          )}
        </span>
      </div>
    </ListGroupItem>
  );
};
