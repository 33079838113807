import React from "react";
import { Company, Option } from "src/types";
import { useCompanies } from "src/util/api/companiesApi";
import { SelectField, SelectFieldProps } from "./SelectField";

export const mapCompaniesToOptions = (companies: Company[]): Option<string>[] =>
  companies.map((c) => ({ label: c.name, value: c.publicUid }));

export const CompanySelectField: React.FC<
  SelectFieldProps & { allowCreate: boolean }
> = ({ allowCreate, ...rest }) => {
  const { data: companiesData, query, create } = useCompanies({ per: 1000 });

  const handleCreate = async (inputValue: string) => {
    if (allowCreate) {
      await create.mutateAsync(
        { data: { name: inputValue } },
        {
          onSuccess: (data) => {
            rest.input.onChange(data.company.publicUid);
          },
        },
      );
    }
  };

  return (
    <SelectField
      {...rest}
      loading={query.isFetching || create.isPending || rest.loading}
      disabled={query.isFetching || create.isPending || rest.disabled}
      onCreateOption={allowCreate ? handleCreate : undefined}
      options={mapCompaniesToOptions(companiesData?.companies ?? [])}
      includeBlank
    />
  );
};
