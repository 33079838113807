import { Icon, Loading } from "@ream/ui";
import clsx from "clsx";
import { clamp } from "lodash-es";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "lucide-react";
import React from "react";
import { Pagination } from "react-bootstrap";

export const TablePager: React.FC<{
  page: number;
  totalPages: number;
  onChange: (_page: number) => void;
  isLoading?: boolean;
  className?: string;
}> = ({ page = 0, totalPages = 0, onChange, isLoading = false, className }) => {
  totalPages = Math.max(totalPages, 1);
  return (
    <Pagination className={clsx("mb-0 small", className)}>
      <Pagination.Item
        disabled={isLoading || page <= 1}
        onClick={() => onChange(1)}
      >
        <Icon icon={ChevronsLeftIcon} />
      </Pagination.Item>

      <Pagination.Item
        disabled={isLoading || page <= 1}
        onClick={() => onChange(clamp(page - 1, 1, totalPages))}
      >
        <Icon icon={ChevronLeftIcon} />
      </Pagination.Item>

      <Pagination.Item disabled>
        {isLoading ? (
          <Loading size="sm" />
        ) : (
          <>
            {page} of {totalPages}
          </>
        )}
      </Pagination.Item>

      <Pagination.Item
        disabled={isLoading || page >= totalPages}
        onClick={() => onChange(clamp(page + 1, 1, totalPages))}
      >
        <Icon icon={ChevronRightIcon} />
      </Pagination.Item>

      <Pagination.Item
        disabled={isLoading || page >= totalPages}
        onClick={() => onChange(totalPages)}
      >
        <Icon icon={ChevronsRightIcon} />
      </Pagination.Item>
    </Pagination>
  );
};
