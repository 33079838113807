import { Alert, BaseModal, Button, ButtonProps } from "@ream/ui";
import { pick } from "lodash-es";
import React from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-final-form";
import { FormSubmitButton } from "src/components/form/FormSubmitButton";
import { Contact } from "src/types";
import { extractErrorMessage } from "src/util/api/apiError";
import { useContact, useUpdateContact } from "src/util/api/contactsApi";
import { validateSchema } from "src/util/validateSchema";
import {
  ContactFields,
  ContactFormData,
  contactSchema,
} from "./AddContactModal";

type Props = {
  contact: Contact | null;
  onSave?: (_updatedContact: Contact) => void | Promise<void>;
  buttonProps?: Partial<ButtonProps>;
};

export const EditContactByIdModal: React.FC<
  Omit<Props, "contact"> & {
    contactId: string;
  }
> = ({ contactId, ...rest }) => {
  const { data: { contact } = { contact: null }, query } = useContact({
    id: contactId,
  });

  if (query.isFetching) {
    return null;
  }

  return <EditContactModal contact={contact} {...rest} />;
};

export const EditContactModal: React.FC<Props> = ({
  contact,
  onSave,
  buttonProps,
}) => {
  const update = useUpdateContact(contact?.publicUid);

  if (!contact) {
    return null;
  }

  const initialValues: ContactFormData = pick(contact, [
    "publicUid",
    "company",
    "firstName",
    "lastName",
    "email",
    "title",
    "phoneNumber",
    "faxNumber",
    "website",
    "address",
  ]);

  initialValues.companyId = contact.company?.publicUid;

  return (
    <BaseModal
      trigger={<Button {...buttonProps}>Edit Contact</Button>}
      title={`Edit ${contact.fullName}`}
      size="lg"
      dismissable
    >
      {({ handleClose }) => {
        const onSubmit = async (formData: ContactFormData) => {
          return update.mutateAsync(
            {
              data: formData,
            },
            {
              onSuccess: (res) => {
                if (onSave) {
                  onSave(res.contact);
                }

                handleClose();
              },
            },
          );
        };

        return (
          <>
            <Form
              initialValues={initialValues}
              keepDirtyOnReinitialize
              onSubmit={onSubmit}
              validate={validateSchema(contactSchema)}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    {update.isError && (
                      <Alert variant="danger">
                        {extractErrorMessage(update.error as Error)}
                      </Alert>
                    )}

                    <ContactFields />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={handleClose}
                      disabled={submitting}
                      variant="secondary"
                    >
                      Cancel
                    </Button>

                    <FormSubmitButton disabledTooltip="You haven't made any changes yet!">
                      Save
                    </FormSubmitButton>
                  </Modal.Footer>
                </form>
              )}
            />
          </>
        );
      }}
    </BaseModal>
  );
};
