import { map } from "lodash-es";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { TextField } from "src/components/form/TextField";
import { COUNTRIES } from "src/util/data/geo";
import { SelectField } from "../form/SelectField";
import { StateSelectField } from "../form/StateSelectField";

export const AddressFields: React.FC<{ prefix?: string }> = ({
  prefix = "address",
}) => {
  return (
    <>
      <Field label="Street" name={`${prefix}.street`} component={TextField} />

      <Row>
        <Col>
          <Field label="City" name={`${prefix}.city`} component={TextField} />
        </Col>
        <Col>
          <Field
            isClearable
            includeBlank
            label="State"
            name={`${prefix}.state`}
            component={StateSelectField}
          />
        </Col>
        <Col>
          <Field label="Zip" name={`${prefix}.zip`} component={TextField} />
        </Col>
      </Row>

      <Field
        isClearable
        includeBlank
        label="Country"
        name={`${prefix}.country`}
        component={SelectField}
        options={map(COUNTRIES, (label, value) => ({ label, value }))}
      />
    </>
  );
};
