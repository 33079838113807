/* eslint-disable no-restricted-imports */

import clsx from "clsx";
import RSSelect, {
  ContainerProps,
  GroupBase,
  Props as RSSelectProps,
  components,
} from "react-select";
import RSCreateSelect from "react-select/creatable";

export type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = RSSelectProps<Option, IsMulti, Group> & {
  size?: "sm" | "lg";
  meta?: any;
};

const SelectContainer = ({
  children,
  selectProps,
  ...props
}: ContainerProps<any>) => {
  // TODO Wyatt if you can fix this grossness it's much appreciated!
  const realSelectProps = selectProps as SelectProps<any, any, any>;
  return (
    <components.SelectContainer {...props} selectProps={selectProps}>
      <div data-test={realSelectProps.meta?.data?.testId}>{children}</div>
    </components.SelectContainer>
  );
};

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ className, size, ...props }: SelectProps<Option, IsMulti, Group>) {
  return (
    <RSSelect
      {...props}
      components={{ SelectContainer }}
      className={clsx(
        className,
        "react-select",
        size === "sm" && "react-select-sm",
        size === "lg" && "react-select-lg",
      )}
      classNamePrefix="react-select"
    />
  );
}

export function CreateSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ className, size, ...props }: SelectProps<Option, IsMulti, Group>) {
  return (
    <RSCreateSelect
      {...props}
      components={{ SelectContainer }}
      className={clsx(
        className,
        "react-select",
        "react-select--creatable",
        size === "sm" && "react-select-sm",
        size === "lg" && "react-select-lg",
      )}
      classNamePrefix="react-select"
    />
  );
}
